<template>
  <div class="entry">
    <div class="entry__video">
      <iframe class="entry__player" :src="playerSrc" width="100%" height="100%" frameborder="0" scrolling="no" allowfullscreen="true"></iframe>

      <!-- <img src="@/assets/play.png" class="entry__control entry__control--play" />
      <img src="@/assets/full-screen.png" class="entry__control entry__control--full-screen" @click="$emit('show-entry')"/> -->
    </div>

    <div class="entry__footer">
      <p class="entry__name">{{ name }}</p>

      <VButton @click="setDeleted(!isDeletedLocal)" v-if="isConfirmed">
        <template v-if="!isDeletedLocal">
          <span>Ukryj</span>
        </template>

        <template v-else>
          <span>Przywróć</span>
        </template>
      </VButton>
    </div>
  </div>
</template>

<script>
import VButton from '@/components/VButton.vue';

export default {
  name: 'Entry',
  components: {
    VButton,
  },
  props: {
    name: {
      type: String,
      default: '',
    },

    videoId: {
      type: String,
      default: '',
    },

    votes: {
      type: Number,
      default: 0,
    },

    isDeleted: {
      type: Boolean,
      default: false,
    },

    isConfirmed: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isDeletedLocal: this.isDeleted,
    };
  },

  computed: {
    playerSrc() {
      return `https://embed.api.video/vod/${this.videoId}#hide-title`;
    },
  },

  methods: {
    setDeleted(deleted) {
      this.isDeletedLocal = deleted;

      this.$emit(deleted ? 'delete' : 'undelete');
    },
  },
};
</script>

<style scoped lang="scss">
.entry {
  width: 100%;

  &__video {
    position: relative;

    width: 100%;
    padding-top: 59.04%;
    background-color: #606060;
    border-radius: 8px;
  }

  &__player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__control {
    position: absolute;

    opacity: 0.8;

    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    &--play {
      top: 50%;
      left: 50%;

      width: 56px;
      height: 56px;

      transform: translate(-50%, -50%);
    }

    &--full-screen {
      bottom: 20px;
      right: 16px;

      width: 30px;
      height: 21px;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
  }

  &__name {
    margin-right: 12px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    font-size: 26px;
    font-weight: 500;
  }

  ::v-deep .button &__vote-icon {
    display: block;
    margin-left: 16px;
    margin-right: -3px;

    &--hover {
      display: none;
    }
  }

  ::v-deep .button:not(.button--disabled):hover &__vote-icon {
    display: none;

    &--hover {
      display: block;
    }
  }
}
</style>
