<template>
  <ul class="grid" :class="{
    'grid--two-columns': twoColumns,
    'grid--three-columns': threeColumns,
  }">
    <li class="grid__item" v-for="item in items" :key="item.id">
      <slot name="item" v-bind="item"></slot>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Grid',
  props: {
    twoColumns: {
      type: Boolean,
      default: false,
    },

    threeColumns: {
      type: Boolean,
      default: false,
    },

    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="scss">
.grid {
  display: flex;
  flex-wrap: wrap;
  padding: 12px;

  margin: -24px;

  &__item {
    padding: 12px;
  }

  &--two-columns &__item {
    flex: 1 1 50%;
    max-width: 50%;
  }

  &--three-columns &__item {
    flex: 1 1 33.3333%;
    max-width: 33.3333%;
  }

  &--two-columns &__item,
  &--three-columns &__item {
    @media screen and (max-width: 1200px) {
      flex: 1 1 50%;
      max-width: 50%;
    }

    @media screen and (max-width: 900px) {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }

}
</style>
