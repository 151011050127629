<template>
  <div class="home">
    <section class="section section--entries">
      <VWrapper>
        <div class="section__top">
          <h1 class="section__header">Pobieranie CSV</h1>
        </div>

        <div class="section__content">
          <VButton @click="downloadCSV('entries')">Pobierz zgłoszenia</VButton>
          <VButton @click="downloadCSV('votes')">Pobierz głosy</VButton>
        </div>
      </VWrapper>
    </section>
  </div>
</template>

<script>
import VWrapper from '@/components/VWrapper.vue';
import VButton from '@/components/VButton.vue';
import Api from '@/Api';

export default {
  name: 'Home',
  components: {
    VWrapper,
    VButton,
  },

  methods: {
    async downloadCSV(type) {
      const { data: csv } = await Api.getCSV(type);

      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);

      link.setAttribute('href', url);
      link.setAttribute('download', `${type}.csv`);
      link.style.visibility = 'hidden';

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    },
  },
};
</script>

<style scoped lang="scss">
.home {
  &__artwork {
    position: absolute;
    right: 0;
    top: 0;

    @media screen and (max-width: 1200px) {
      display: none;
    }
  }
}

.section {
  padding: 80px 24px;

  &:nth-child(even) {
    background-color: #F3F3F3;
  }

  &__top {
    @media screen and (max-width: 900px) {
      text-align: center;
    }
  }

  &__header {
    font-size: 40px;
    line-height: 49px;
    font-weight: 700;
  }

  &__subheader {
    margin-top: 12px;

    font-size: 32px;
    line-height: 42px;
  }

  &__description {
    margin-top: 36px;

    font-size: 22px;
    line-height: 27px;
    font-weight: 500;
  }

  &__content {
    margin-top: 60px;

    ::v-deep .button:not(:first-child) {
      margin-left: 20px;
    }
  }

  &--cta &__top,
  &--cta &__content {
    max-width: 564px;

    @media screen and (max-width: 1200px) {
      max-width: 100%;
      text-align: center;
    }
  }

  &--entries &__top {
    display: flex;
    align-items: center;

    justify-content: space-between;

    @media screen and (max-width: 1100px) {
      display: block;
    }
  }
}

.sort {
  @media screen and (max-width: 1100px) {
    margin-top: 32px;
  }

  &__header {
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;

    color: #909090;
  }

  &__options {
    display: flex;

    margin-left: -20px;

    @media screen and (max-width: 1100px) {
      flex-wrap: wrap;
      justify-content: center;
      margin-left: 0;
      margin-top: 20px;
    }
  }

  &__option {
    padding: 10px 20px;

    font-weight: 600;
    color: rgb(0, 0, 0, 25%);

    cursor: default;

    &--active {
      color: #000000;
    }

    &:not(&--active) {
      cursor: pointer;

      &:hover {
        background-color: #F8F8F8;
        border-radius: 9999px;
      }
    }

    @media screen and (max-width: 1100px) {
      min-width: 162px;
      text-align: center;
    }
  }
}
</style>
