<template>
  <div class="wrapper" :class="{
    'wrapper--wide': wide,
  }">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Wrapper',

  props: {
    wide: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  position: relative;
  width: 100%;
  max-width: 1160px;
  margin: 0 auto;

  &--wide {
    max-width: 1300px;
  }
}
</style>
