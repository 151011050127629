import Vue from 'vue';
import VueRouter from 'vue-router';
import Visible from '@/views/Visible.vue';
import Hidden from '@/views/Hidden.vue';
import Unconfirmed from '@/views/Unconfirmed.vue';
import CSV from '@/views/CSV.vue';

Vue.use(VueRouter);

export default new VueRouter({
  routes: [
    {
      path: '/visible',
      name: 'Visible',
      component: Visible,
    },
    {
      path: '/hidden',
      name: 'Hidden',
      component: Hidden,
    },
    {
      path: '/unconfirmed',
      name: 'Unconfirmed',
      component: Unconfirmed,
    },
    {
      path: '/csv',
      name: 'csv',
      component: CSV,
    },
    {
      path: '*',
      redirect: '/visible',
    },
  ],
});
