/* eslint-disable class-methods-use-this */
/* eslint-disable object-curly-newline */
import axios from 'axios';

class Api {
  constructor() {
    this.token = '';
    this.axios = axios.create({
      baseURL: 'https://sda-api.bricodes.com',
      // baseURL: 'http://localhost:3000',
    });
  }

  setToken(token) {
    this.token = token;
  }

  async getEntries(params = {}) {
    return this.axios({
      method: 'GET',
      url: '/entries/all',
      headers: {
        token: this.token,
      },
      params,
    });
  }

  async getCSV(type) {
    return this.axios({
      method: 'GET',
      url: `/${type}/all/csv`,
      headers: {
        token: this.token,
      },
    });
  }

  async ping() {
    try {
      await this.axios({
        method: 'GET',
        url: '/entries/all',
        headers: {
          token: this.token,
        },
      });

      return true;
    } catch (error) {
      return false;
    }
  }

  async deleteEntry(entryId) {
    return this.axios({
      method: 'POST',
      url: `/entries/all/${entryId}/delete`,
      headers: {
        token: this.token,
      },
    });
  }

  async undeleteEntry(entryId) {
    return this.axios({
      method: 'POST',
      url: `/entries/all/${entryId}/undelete`,
      headers: {
        token: this.token,
      },
    });
  }
}

export default new Api();
