<template>
  <div class="app" v-if="isLoggedIn">
    <div class="app__header">
      <VWrapper wide>
        <img src="@/assets/sda-logo.svg" class="app__logo" />

        <div class="app__menu">
          <router-link to="/visible">Widoczne</router-link>
          <router-link to="/hidden">Ukryte</router-link>
          <router-link to="/unconfirmed">Niepotwierdzone</router-link>
          <router-link to="/csv">CSV</router-link>
        </div>

        <div class="app__menu-button" :class="{
          'app__menu-button--open': isMobileMenuOpen,
        }" @click="isMobileMenuOpen = !isMobileMenuOpen"></div>

        <div class="app__menu--mobile" v-if="isMobileMenuOpen">
          <router-link to="/visible">Widoczne</router-link>
          <router-link to="/hidden">Ukryte</router-link>
          <router-link to="/unconfirmed">Niepotwierdzone</router-link>
          <router-link to="/csv">CSV</router-link>
        </div>
      </VWrapper>
    </div>

    <div class="app__content">
      <router-view :key="$route.fullPath" @delete-entry="deleteEntry($event)" @undelete-entry="undeleteEntry($event)" />
    </div>
  </div>
</template>

<script>
import VWrapper from '@/components/VWrapper.vue';
import Api from '@/Api';

export default {
  name: 'App',

  components: {
    VWrapper,
  },

  async mounted() {
    // eslint-disable-next-line no-restricted-globals, no-alert
    Api.setToken(prompt(''));

    this.isLoggedIn = await Api.ping();

    if (!this.isLoggedIn) {
      window.location.reload();
    }
  },

  data: () => ({
    isMobileMenuOpen: false,
    isLoggedIn: false,
  }),

  methods: {
    async deleteEntry({ entryId }) {
      await Api.deleteEntry(entryId);
    },

    async undeleteEntry({ entryId }) {
      await Api.undeleteEntry(entryId);
    },
  },
};
</script>

<style lang="scss">
@import 'reset-css';

@font-face {
  font-family: 'Gilroy';
  src: url('~@/assets/fonts/Gilroy-MediumItalic.woff2') format('woff2'),
    url('~@/assets/fonts/Gilroy-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('~@/assets/fonts/Gilroy-Medium.woff2') format('woff2'),
    url('~@/assets/fonts/Gilroy-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('~@/assets/fonts/Gilroy-Semibold.woff2') format('woff2'),
    url('~@/assets/fonts/Gilroy-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('~@/assets/fonts/Gilroy-Bold.woff2') format('woff2'),
    url('~@/assets/fonts/Gilroy-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  overflow-y: scroll;

  &.has-popup {
    overflow-y: hidden;
  }
}

.app {
  font-family: 'Gilroy', Arial, Helvetica, sans-serif;
  color: #000000;

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    height: 100px;
    padding: 0 24px;

    box-shadow: 0 0 10px rgb(112 112 112 / 10%);

    .wrapper {
      position: static;
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
        display: inline-block;
        padding: 10px 0;
        text-decoration: none;
        font-weight: 900;
        color: #000000;

        &:not(:first-child) {
          margin-left: 30px;
        }

        &:hover {
          color: rgba(0, 0, 0, 0.6);
        }

        &:nth-child(4) {
          padding-left: 30px;
          border-left: 1px solid #000000;
        }
      }
    }
  }

  &__logo {
    display: block;
    height: 52px;
    width: 100%;
    max-width: 168px;
  }

  &__menu {
    @media screen and (max-width: 1100px) {
      display: none;
    }

    &--mobile {
      display: none;

      position: absolute;
      top: 100%;
      width: 100%;
      right: 0;
      background-color: #ffffff;
      z-index: 100;

      box-shadow: 0 3px 6px rgb(0 0 0 / 10%);

      a {
        display: block !important;
        width: 100%;
        text-align: right;

        padding: 20px 32px !important;

        &:not(:first-child) {
          margin-left: 0 !important;
        }

        &:nth-child(4) {
          border-left: none !important;
          padding-left: 0 !important;
        }
      }


      @media screen and (max-width: 1100px) {
        display: block;
      }
    }
  }

  &__menu-button {
    display: none;

    width: 24px;
    height: 24px;
    position: relative;

    cursor: pointer;

    @media screen and (max-width: 1100px) {
      display: block;
    }

    &::before,
    &::after {
      content: '';
      width: 24px;
      height: 2px;
      background-color: #000;
      position: absolute;
      left: 0;
      transition: top .2s ease-in-out,bottom .2s ease-in-out,transform .2s ease-in-out;
    }

    &::before {
      top: 7px;
    }

    &::after {
      bottom: 7px;
    }
  }

  &__menu-button--open {
    &::before {
      transform: rotate(45deg);
      top: 11px;
    }

    &::after {
      transform: rotate(-45deg);
      bottom: 11px;
    }
  }
}

.footer {
  padding: 24px;
  background-color: #000000;

  &__columns {
    display: flex;
    padding: 0 12px;
    margin: 0 -24px;

    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
    }
  }

  &__column {
    padding: 0 12px;

    &--left {
      flex: 0 0 220px;

      margin-right: 100px;

      @media screen and (max-width: 900px) {
        flex: 1 1 auto;
        margin-right: 0;
      }
    }

    &--right {
      flex: 1 1 auto;

      @media screen and (max-width: 900px) {
        margin-top: 32px;
      }
    }
  }

  &__menu {
    display: flex;
    flex-wrap: wrap;
    padding: 0 12px;
    margin: 0 -24px;
  }

  &__links {
    flex: 1 1 25%;
    max-width: 25%;

    padding: 0 12px;

    @media screen and (max-width: 1200px) {
      flex: 1 1 50%;
      max-width: 50%;
    }

    &:nth-child(3),
    &:nth-child(4) {
      @media screen and (max-width: 1200px) {
        margin-top: 32px;
      }
    }

    @media screen and (max-width: 600px) {
      flex: 1 1 100%;
      max-width: 100%;
    }

    &:not(:first-child) {
      @media screen and (max-width: 600px) {
        margin-top: 32px;
      }
    }
  }

  &__link {
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;

    &:not(:first-child) {
      margin-top: 14px;
    }

    a {
      text-decoration: none;
      color: inherit;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  &__links:last-child {
    display: flex;
    flex-wrap: wrap;
  }

  &__links:last-child &__link {
    flex: 1 1 50%;
    max-width: 50%;
  }

  .app__logo {
    margin-bottom: 36px;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.25em;
    color: #ffffff;

    &:not(:first-child) {
      margin-top: 20px;
    }

    a {
      color: inherit;
      text-decoration: none;
    }

    small {
      font-size: 12px;
      line-height: 1.25em;
    }
  }

  &__copy {
    text-align: center;

    p {
      font-weight: 600;
    }
  }
}

.form {
  &__row {
    margin-top: 20px;
  }

  &__field input[type="text"],
  &__filename {
    display: block;
    width: 100%;
    padding: 16px 32px;

    font-family: 'Gilroy', Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    color: #000000;

    background-color: #ffffff;
    border-radius: 6px;

    outline: none;
  }

  &__field {
    display: block;
    position: relative;

    input[type="checkbox"],
    input[type="file"] {
      position: absolute;
      left: -9999px;
    }

    &--checkbox {
      padding-left: 42px;
    }
  }

  &__field--checkbox &__label,
  &__field--comment &__label {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
  }

  &__filename {
    cursor: pointer;
  }

  &__label {
    margin-bottom: 14px;
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;

    a {
      color: inherit;
    }
  }

  &__checkbox {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;

    background-color: #ffffff;

    border: 4px solid #ffffff;

    border-radius: 4px;

    cursor: pointer;
  }

  &__field input[type="checkbox"]:checked + .form__checkbox {
    background-color: #000000;
  }

  &__field--invalid &__label {
    color: #ff0000;
  }
}

.progress {
  position: relative;
  width: 315px;
  height: 315px;

  &__circle {
    width: 300px;
    height: 300px;
    transform: rotate(-90deg);
  }

  &__background {
    fill: none;
    stroke: #ffffff;
    stroke-width: 2;
  }

  &__foreground {
    fill: none;
    stroke: #88F2CC;
    stroke-dasharray: 100 100;
    stroke-dashoffset: 100;
    stroke-linecap: square;
    stroke-width: 2;
    transition: stroke-dashoffset 0.1s linear;
  }

  &__percent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    font-size: 60px;
    font-weight: bold;
    color: #ffffff;

    span {
      display: inline-block;
      vertical-align: top;
      font-size: 40px;
    }
  }
}

.message {
  margin-top: 200px;
  text-align: center;

  &__icon {
    display: block;
    width: 100%;
    max-width: 77px;
    margin: 0 auto;
  }

  &__title {
    margin-top: 35px;
    margin-bottom: 32px;

    font-size: 40px;
    line-height: 49px;
    font-weight: bold;
  }

  &__content {
    font-size: 28px;
  }
}
</style>
