<template>
  <div class="home">
    <section class="section section--entries">
      <VWrapper>
        <div class="section__top">
          <h1 class="section__header">Widoczne zgłoszenia</h1>

          <div class="sort">
            <p class="sort__header">Sortuj po</p>
            <p class="sort__options">
              <span class="sort__option" :class="sortKey === 'votes' ? 'sort__option--active' : ''" @click="sort('votes', true)">Ilość głosów</span>
              <span class="sort__option" :class="sortKey === 'created' && sortReverse ? 'sort__option--active' : ''" @click="sort('created', true)">Od najnowszych</span>
              <span class="sort__option" :class="sortKey === 'created' && !sortReverse ? 'sort__option--active' : ''" @click="sort('created', false)">Od najstarszych</span>
            </p>
          </div>
        </div>

        <div class="section__content">
          <VGrid three-columns :items="entriesSortedBy(sortKey, sortReverse)">
            <template #item="{ _id, name, videoId, votes, isConfirmed, isDeleted }">
              <VEntry :name="name" :video-id="videoId" :votes="votes" :is-confirmed="isConfirmed" :is-deleted="isDeleted" @delete="$emit('delete-entry', { entryId: _id })" @undelete="$emit('undelete-entry', { entryId: _id })" />
            </template>
          </VGrid>
        </div>
      </VWrapper>
    </section>
  </div>
</template>

<script>
import VWrapper from '@/components/VWrapper.vue';
import VGrid from '@/components/VGrid.vue';
import VEntry from '@/components/VEntry.vue';
import VButton from '@/components/VButton.vue';
import Api from '@/Api';

export default {
  name: 'Home',
  components: {
    VWrapper,
    VGrid,
    VEntry,
  },

  async created() {
    const { data: apiEntries } = await Api.getEntries({
      isDeleted: false,
      isConfirmed: true,
    });

    this.entries = apiEntries.map((entry) => ({
      votes: 0,
      ...entry,
    }));
  },

  data: () => ({
    sortKey: 'votes',
    sortReverse: true,
    userVotesIds: [],
    entries: [],
  }),

  methods: {
    sort(key, reverse) {
      this.sortKey = key;
      this.sortReverse = reverse;
    },

    entriesSortedBy(key, reverse = false) {
      return [...this.entries].sort((entryA, entryB) => (reverse
        ? entryB[key] - entryA[key]
        : entryA[key] - entryB[key]
      ));
    },
  },
};
</script>

<style scoped lang="scss">
.home {
  &__artwork {
    position: absolute;
    right: 0;
    top: 0;

    @media screen and (max-width: 1200px) {
      display: none;
    }
  }
}

.section {
  padding: 80px 24px;

  &:nth-child(even) {
    background-color: #F3F3F3;
  }

  &__top {
    @media screen and (max-width: 900px) {
      text-align: center;
    }
  }

  &__header {
    font-size: 40px;
    line-height: 49px;
    font-weight: 700;
  }

  &__subheader {
    margin-top: 12px;

    font-size: 32px;
    line-height: 42px;
  }

  &__description {
    margin-top: 36px;

    font-size: 22px;
    line-height: 27px;
    font-weight: 500;
  }

  &__content {
    margin-top: 60px;
  }

  &--cta &__top,
  &--cta &__content {
    max-width: 564px;

    @media screen and (max-width: 1200px) {
      max-width: 100%;
      text-align: center;
    }
  }

  &--entries &__top {
    display: flex;
    align-items: center;

    justify-content: space-between;

    @media screen and (max-width: 1100px) {
      display: block;
    }
  }
}

.sort {
  @media screen and (max-width: 1100px) {
    margin-top: 32px;
  }

  &__header {
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;

    color: #909090;
  }

  &__options {
    display: flex;

    margin-left: -20px;

    @media screen and (max-width: 1100px) {
      flex-wrap: wrap;
      justify-content: center;
      margin-left: 0;
      margin-top: 20px;
    }
  }

  &__option {
    padding: 10px 20px;

    font-weight: 600;
    color: rgb(0, 0, 0, 25%);

    cursor: default;

    &--active {
      color: #000000;
    }

    &:not(&--active) {
      cursor: pointer;

      &:hover {
        background-color: #F8F8F8;
        border-radius: 9999px;
      }
    }

    @media screen and (max-width: 1100px) {
      min-width: 162px;
      text-align: center;
    }
  }
}
</style>
