<template>
  <a class="button" :class="{
    'button--biggest': biggest,
    'button--bigger': bigger,
    'button--secondary': secondary,
    'button--disabled': disabled,
  }" @click="!disabled && $emit('click')">
    <slot />
  </a>
</template>

<script>
export default {
  name: 'Button',
  props: {
    bigger: {
      type: Boolean,
      default: false,
    },

    biggest: {
      type: Boolean,
      default: false,
    },

    secondary: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  min-width: 160px;
  min-height: 36px;

  padding: 8px 20px;

  font-size: 15px;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  color: #F25F03;

  border: 2px solid #F25F03;
  border-radius: 9999px;

  cursor: pointer;
  user-select: none;

  &:not(&--disabled):hover {
    color: #000000;
    background-color: #F25F03;
  }

  &--bigger {

  }

  &--biggest {
    padding: 16px 46px;

    font-size: 25px;
    color: #000000;

    background-color: #F25F03;

    border: none;
  }

  &--secondary {
    border-color: #ffffff;
    color: #ffffff;
  }

  &--secondary:not(&--disabled):hover {
    background-color: transparent;
    border-color: #ff7234;
    color: #ff7234;
  }

  &--biggest:not(&--disabled):hover {
    background-color: #ff7234;
  }

  &--disabled {
    border-color: #000000;
    background-color: #000000;
    color: #F25F03;
    cursor: default !important;
  }

  &--secondary.button--disabled {
    visibility: hidden;
  }
}
</style>
